<template>
  <div class="chart-container">
    <div class="chart__heading">
      <div class="chart__heading-title">{{ $t('chart.line.title') }}</div>
      <div class="chart__heading-subtitle">{{ filterTitle }}</div>
      <b-dropdown
          variant="background"
          class="chart-options"
          toggle-class="text-decoration-none rounded-10 px-4 py-4 "
          menu-class="rounded-8"
          no-caret
          dropleft
      >
        <template v-slot:button-content>
          <vertical-dots-icon/>
        </template>

        <b-dropdown-item @click="()=>changeFilter(7,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_7_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(30,'days')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_30_days') }}
          </span>
        </b-dropdown-item>

        <b-dropdown-item @click="()=>changeFilter(12,'months')">
          <span class="text-regular-14 text-font-secondary">
            {{ $t('chart.last_12_months') }}
          </span>
        </b-dropdown-item>


      </b-dropdown>
    </div>
    <v-chart class="chart" :option="option" :autoresize="true"/>
  </div>
</template>

<script>

export default {
  props: {
    data: Object
  },
  computed: {
    filterTitle() {
      if (this.filter) {
        const {value, type} = this.filter;
        return this.$i18n.t('chart.last_' + value + '_' + type);
      }

      return this.$i18n.t('chart.last_12_months');
    },

    periods() {
      let temp = [];

      let prefix = "periods."
      if (this.data.translate == false) {
        prefix = "";
      }
      for (let i = 0; i < this.data.periods.length; i++) {
        let _temp = this.data.periods[i];
        temp.push(this.$i18n.t(prefix + _temp.toString().toLowerCase()))
      }
      return temp;
    },

    option() {
      return {
        tooltip: {
          trigger: 'axis',
          formatter: "<span class='tool_tip_text start'>" + '{a0} : {c0} ' + this.$t('common.rial') + '  <span> {a1} : <span class="gold">{c1}</span> ' + this.$t('common.rial') + "</span>",
          className: "char-bar-tooltip line",
          position: 'left',

        },
        grid: {
          left: '0%',
          right: '3%',
          bottom: '14%',
          containLabel: true
        },
        legend: {
          show: false,
        },
        textStyle: {
          fontFamily: 'din-next'
        },
        color: ['transparent', '#7367F0'],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.periods
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: this.$t('financial.sales'),
            type: 'line',
            stack: 'Total',
            data: this.data.sales
          },
          {
            name: this.$t('financial.profit'),
            type: 'line',
            stack: 'Total',
            data: this.data.profits
          }
        ],
        media: [ // each rule of media query is defined here
          {
            query: {
              maxAspectRatio: 1
            },   // write rule here

          },
        ]
      }
    }
  },

  data() {
    return {
      filter: ''
    }
  },
  methods: {
    changeFilter(value, type) {
      this.filter = {value, type};
      this.$emit('changeFilter', this.filter);
    }
  }
};
</script>

<style scoped>

</style>
